import React from 'react';
import { Col, FormGroup, Row } from 'reactstrap';
import ErrorHandler from '../../../partials/error/error-handler-component';
import BlockModel from '../../../models/block.model';
import BlockValidation from '../../../helpers/block-validation.helper';
import { blockWrapperService, blockManagementService } from '../../../subcomponents/blocky.component';
import { createBlock } from '../../partials/helpers/blocky-sidebar-communication/blocky-sidebar-communication.helper';
import { WidgetsV2Ids } from '../../../constants/block.types';
import CompetitionModel from '../../../../../../models/v2/competition/entity/competition.model';
import { SportTypes } from '../../../../../../constants/sport.types';
import { SPORT_SELECT_ATTRIBUTES } from '../helpers/component-attributes.constants';
import TennisAthleteProgrammeModel from './models/tennis-athlete-programme.model';
import TennisTournamentModel from '../../../../../../models/v2/tennis-tournament/tennis-tournament.model';
import TennisRoundModel from '../../../../../../models/v2/round/tennis-round/tennis-round.model';
import PlayerModel from '../../../../../../models/v2/player/entity/player.model';
import { featuresService } from '../../../../../../App';
import { FeatureTypes } from '../../../../../../services/feature-service/features.enum';
import SportsEntitySelect from '../../../partials/sports-team-select/sports-entity-select.component';
import { ContentTypes } from '../../../../../../constants/content-types';
import DisplayOddsComponent from '../../../partials/bookmaker-select/display-odds.component';
import SportBookmakerSelect from '../../../partials/sport-bookmaker-select/sport-bookmaker-select.component';
import SportOddsBookmakerModel from '../../../../../../models/sport-odds/sport-odds-bookmaker.model';
import { TennisTournamentSelectComponent } from '../../partials/tennis-tournament-select/tennis-tournament-select.component';
import { TennisTournamentRoundsSelectComponent } from '../../partials/tennis-tournament-rounds-select/tennis-tournament-rounds-select.component';
import SortDirectionSelectComponent from '../../partials/sort-direction-select/sort-direction-select.component';
import { SelectMenuOptionType } from '../../../../../../models/v2/general/select.model';
import { onRefreshTimeChangeFromMainComponent } from '../../partials/refresh-time-input/helper';
import RefreshTimeWrapper from '../../partials/refresh-time-input/refresh-time-wrapper';

type Properties = {
	block: BlockModel;
	t: any;
	contentData: any;
	contentLanguage: string;
};

type State = {
	widgetModel: TennisAthleteProgrammeModel;
	isValid: boolean;
};

export default class TennisAthleteProgrammeEditComponent extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			widgetModel: createBlock(
				WidgetsV2Ids.widgetTennisAthleteProgramme,
				props.block.data.preview && props.block.data.preview.widgetModel,
				props.contentData,
			),
			isValid: true,
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	onSave = (index: number) => {
		const data = { widgetModel: this.state.widgetModel };
		const validation = new BlockValidation();
		const isValid = !!validation.validate(this.props.block, data);
		this.setState({ ...this.state, isValid });

		const bookmakerSelectionEnabled = featuresService && featuresService.areFeaturesEnabled([FeatureTypes.ALLOW_BOOKMAKER_SELECTION]);
		const athleteProgrammeModel = TennisAthleteProgrammeModel.builder(this.state.widgetModel)
			.withBookmakers(bookmakerSelectionEnabled ? this.state.widgetModel.bookmakers : null)
			.build();

		if (isValid) {
			const block = Object.assign({}, this.props.block);
			block.data.preview = { widgetModel: athleteProgrammeModel };
			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	updateWidgetModelState = (model: TennisAthleteProgrammeModel) => {
		this.setState({ ...this.state, widgetModel: model });
	};

	onPlayerChange = (player: PlayerModel) => {
		this.updateWidgetModelState(
			TennisAthleteProgrammeModel.builder(this.state.widgetModel)
				.withAthlete(player)
				.withTournament({} as TennisTournamentModel)
				.withTournaments([])
				.withCompetition({} as CompetitionModel)
				.withRounds([])
				.withAvailableRounds([])
				.withDisplayOdds(true)
				.build(),
		);
	};

	onTournamentSelect = (tournament: TennisTournamentModel, rounds: TennisRoundModel[], competition: CompetitionModel) => {
		this.onRoundsSelect([]);
		this.updateWidgetModelState(
			TennisAthleteProgrammeModel.builder(this.state.widgetModel)
				.withTournament(tournament)
				.withCompetition(competition)
				.withRounds(rounds)
				.withAvailableRounds(rounds)
				.withBookmakers([])
				.build(),
		);
	};

	onRoundsSelect = (rounds: TennisRoundModel[]) => {
		this.updateWidgetModelState(TennisAthleteProgrammeModel.builder(this.state.widgetModel).withRounds(rounds).withBookmakers([]).build());
	};

	onDisplayOddsChange = (e: any) => {
		this.updateWidgetModelState(
			TennisAthleteProgrammeModel.builder(this.state.widgetModel).withDisplayOdds(e.target.checked).withBookmakers([]).build(),
		);
	};

	onBookmakerChange = (bookmakers: SportOddsBookmakerModel[]) => {
		this.updateWidgetModelState(TennisAthleteProgrammeModel.builder(this.state.widgetModel).withBookmakers(bookmakers).build());
	};

	onSortDirectionSelect = (sortDirection: any) => {
		this.updateWidgetModelState(
			TennisAthleteProgrammeModel.builder(this.state.widgetModel)
				.withSortDirection(sortDirection && sortDirection.value)
				.build(),
		);
	};

	onRefreshTimeChange = (refreshTimeData: SelectMenuOptionType) => {
		onRefreshTimeChangeFromMainComponent(refreshTimeData, TennisAthleteProgrammeModel, this.state.widgetModel, this.updateWidgetModelState);
	};

	render() {
		const { t, contentLanguage, block } = this.props;
		const { widgetModel, isValid } = this.state;
		const bookmakerSelectionEnabled = featuresService.areFeaturesEnabled([FeatureTypes.ALLOW_BOOKMAKER_SELECTION]);
		return (
			<div data-qa='tennis-athlete-programme-edit-component'>
				<Row className='mb-2'>
					<Col>
						<FormGroup>
							<SportsEntitySelect
								inputId='player-select'
								isMulti={false}
								t={t}
								value={widgetModel.athlete ? widgetModel.athlete : null}
								onSelect={(player: PlayerModel) => this.onPlayerChange(player)}
								isValid={isValid}
								sport={SportTypes.TENNIS}
								entityType={[ContentTypes.PLAYER, ContentTypes.TEAM]}
								isRequired
							/>
						</FormGroup>
					</Col>
					<Col>
						<FormGroup>
							<TennisTournamentSelectComponent
								onTournamentSelect={this.onTournamentSelect}
								selectedTournament={
									widgetModel.tournament && widgetModel.tournament.id && widgetModel.tournament.id.length > 0
										? widgetModel.tournament
										: ({} as TennisTournamentModel)
								}
								tournaments={widgetModel.tournaments}
								isValid={isValid}
								language={contentLanguage}
								isClearable
								sport={SportTypes.TENNIS}
								id={`${SportTypes.TENNIS}-${SPORT_SELECT_ATTRIBUTES.TOURNAMENT_SELECT}`}
								isAthleteProgramme
								playerId={widgetModel.athlete && widgetModel.athlete.id ? widgetModel.athlete.id : null}
								preselect={block.data && !block.data.content}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col className='mb-2'>
						<TennisTournamentRoundsSelectComponent
							onRoundsSelect={this.onRoundsSelect}
							selectedRounds={widgetModel.rounds && widgetModel.rounds.length > 0 ? widgetModel.rounds : []}
							rounds={widgetModel.availableRounds}
							isValid={isValid}
							language={contentLanguage}
							isClearable
							sport={SportTypes.TENNIS}
							id={`${SportTypes.TENNIS}-${SPORT_SELECT_ATTRIBUTES.ROUND_SELECT}`}
							preselectRounds
						/>
					</Col>
					<Col className='mb-2'>
						<FormGroup>
							<SortDirectionSelectComponent
								selectedSortDirection={widgetModel.sortDirection ? widgetModel.sortDirection : ''}
								t={t}
								onSortDirectionSelect={this.onSortDirectionSelect}
								withLabel
								isClearable
								id={`${SportTypes.TENNIS}-${SPORT_SELECT_ATTRIBUTES.SORT_DIRECTION_SELECT}`}
							/>
						</FormGroup>
					</Col>
				</Row>
				<hr />
				<DisplayOddsComponent
					t={t}
					block={this.props.block}
					displayOddsChecked={widgetModel.displayOdds ? widgetModel.displayOdds : false}
					onDisplayOddsChange={this.onDisplayOddsChange}
					id={`${SportTypes.TENNIS}-${SPORT_SELECT_ATTRIBUTES.DISPLAY_ODDS_SELECT}`}
				/>
				{widgetModel && widgetModel.displayOdds && bookmakerSelectionEnabled && (
					<Row className='mb-2'>
						<Col>
							<SportBookmakerSelect
								isValid={this.state.isValid}
								showAllBookmakers={false}
								bookmakerSelectionEnabled={bookmakerSelectionEnabled}
								t={this.props.t}
								valueList={widgetModel.bookmakers ? widgetModel.bookmakers : []}
								onSelect={this.onBookmakerChange}
								contentLanguage={this.props.contentLanguage}
								sportType={SportTypes.TENNIS}
								id={`${SportTypes.TENNIS}-${SPORT_SELECT_ATTRIBUTES.BOOKMAKER_SELECT}`}
							/>
						</Col>
					</Row>
				)}
				{(!isValid && widgetModel.athlete && widgetModel.athlete.id === '') ||
					(widgetModel.athlete === null && (
						<div className='mb-3'>
							<ErrorHandler t={t} errorMessage='no_player_selected' />
						</div>
					))}
				<RefreshTimeWrapper value={this.state.widgetModel.refreshTime} onRefreshTimeChange={this.onRefreshTimeChange} />
			</div>
		);
	}
}
